<template><span></span></template>
<script>
export default {
    name: "Resizer",
    emits: ['resize'],
    methods: {
        'sendMeasures': function() {
            this.$emit('resize', { "width": window.innerWidth, "height": window.innerHeight });
        }
    },
    mounted() {
        window.addEventListener("resize", this.sendMeasures);
    },
    unmounted() {
        window.removeEventListener("resize", this.sendMeasures);
    },
    data() {
        return {}
    },
};
</script>