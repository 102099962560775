<template>
  <Dialog :modal="true" v-model:visible="internal.show">
    <template #header>
      <span class="p-dialog-title">{{ fields.title }}</span>
    </template>
    <div class="p-grid">
      <div class="p-col-3 popup-icon">
        <i :class="fields.icon" />
      </div>
      <div class="p-col popup-body">
        <span class="p-dialog-message">{{ fields.message }}</span>
      </div>
    </div>
    <template #footer>
      <Button ref="okBtn" :label="$t('common.ok_button')" @click="acceptDialog()" :class="fields.acceptClass" :autofocus="fields.focus == 'ok'" />
      <Button ref="cancelBtn" :label="$t('common.cancelButton')" @click="cancelDialog()" :class="fields.rejectClass" :autofocus="fields.focus == 'cancel'" />
    </template>
  </Dialog>
</template>

<script>
export default {
  name : 'ConfirmDialog',
  data(){
      return {
          internal:{
              show:false,
              resolve:null
          },
          defaults: {
            title: "",
            message: "",
            icon: "",
            acceptClass: "",
            rejectClass: "",
            focus: "cancel"
          },
          fields: {
            title: "",
            message: "",
            icon: "",
            acceptClass: "",
            rejectClass: "",
            focus: "cancel"
          }
      }
  },
  methods: {
      acceptDialog(){
        this.$emit("accept");
        this.emitHandler("ok");
      },
      cancelDialog(){
          this.$emit("cancel");
          this.emitHandler("cancel");
      },
      emitHandler(button){
        this.internal.show = false;
        if (this.internal.resolve) this.internal.resolve(button);
      },
      async show(data) {
        return new Promise(resolve =>{
            this.fields = { ...this.defaults, ...data };
            this.internal.resolve = resolve;
            this.internal.show = true;
        });
      },
  }
}
</script>