<template>
  <top-bar />
  <side-menu-bar :options="options" :default="defaultOptions" />
  <side-page-content><router-view /></side-page-content>
  <CommonDialog ref="cDialog"></CommonDialog>
  <TourDialog ref="tourDialog"></TourDialog>
  <Toast position="bottom-right" group="tmsgs" />
  <v-tour name="adminTour" :steps="tour.steps" :callbacks="tourCallbacks"></v-tour>
  <loading v-model:active="isLoading" loader="dots" color="#00b1fc" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import TopBar from '../components/Topbar.vue';
import SideMenuBar from '../components/SideMenuBar.vue';
import SidePageContent from '../components/SidePageContent.vue';
import Loading from 'vue-loading-overlay';
import CommonDialog from "../components/CommonDialog.vue";
import TourDialog from '../components/TourDialog.vue';

import 'vue-loading-overlay/dist/vue-loading.css';
import roles, { hasPermissions } from '../enums/roles';
import { adminService } from '../services/admin.service';
import { mapState, mapGetters } from 'vuex';
import { HBChat } from '../helpers/HSHelper';

export default {
  name: 'AdminLayout',
  components: {
    TopBar,
    SideMenuBar,
    SidePageContent,
    Loading,
    CommonDialog,
    TourDialog
  },
  data() {
    return {
      defaultOptions: 'workspaces',
      visibleAccount: true,
      tourCallbacks: {
        onFinish: this.onTourFinish,
        onSkip: this.onTourSkipped
      }
    };
  },
  methods: {
    onTourFinish() {
      this.setTourDone();
    },
    onTourSkipped() {
      this.setTourDone();
    },
    setTourDone() {
      adminService.setUserTour(true, { config: { ajaxOptions: { ajaxState: false } } });
    }
  },
  mounted() {
    if (!this.tourDone && hasPermissions(this.user.role, roles.ADMIN)) {
      // this.$refs.tourDialog.show().then(() => {
      this.$tours['adminTour'].start();
      // });
    }
  },
  computed: {
    isLoading() {
      return (
        this.$store.state.ajaxState && !this.$store.state.isPing && !this.$store.state.isDownload
      );
    },
    ...mapState({
      HSChatLoaded: state => state.hubSpotChatConfig.loaded,
      downloadCompleted: state => state.installerDownload.completed,
      downloadError: state => state.installerDownload.error,
      downloadErrorMsg: state => state.installerDownload.errorMsg,
      tour: state => state.tour,
      user: state => state.auth.user,
      tourDone: state => state.auth.user.endTour,
    }),
    ...mapState('tour', {
      steps: (state) => state.steps,
    }),
    ...mapGetters({
      options: 'menuOptions',
    }),
  },
  watch: {
    HSChatLoaded: {
      immediate: true,
      handler() {
        HBChat.load();
      }
    },
    downloadCompleted(val) {
      if (val === true) {
        this.$refs.cDialog.show({
          title: this.$t('downloadInstaller.downloadSuccesful.title'),
          message: this.$t('downloadInstaller.downloadSuccesful.message'),
          icon: 'thin-alert',
        });
      }
    },
    downloadError(val) {
      if (val === true) {
        this.$refs.cDialog.show({
          title: this.$t('downloadInstaller.downloadError.title'),
          message: this.$t('downloadInstaller.downloadError.message'),
          icon: 'thin-alert',
        });
      }
    },
  },
};
</script>
