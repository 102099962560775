<template>
  <Dialog :modal="true" v-model:visible="internal.show" :style="{width: '30vw'}">
    <template #header>
      <span class="p-dialog-title"></span>
    </template>
    <Card>
        <template #title>
            Welcome to Thinfinity Workspaces
        </template>
        <template #content>
            Here you can create and manage digital workspaces, giving your people selective browser access to desktops, apps and internal digital resources.
            You will be able to deliver your company’s business-critical apps in minutes, taking advantage of our Secure Tunnelling technology.
        </template>
        <template #footer>
        </template>
    </Card>
    <template #footer>
      <Button
        ref="okBtn"
        @click="startTour"
        :label="'Start Tour'"
        :class="acceptClass" />
    </template>
  </Dialog>
</template>

<script>
import DialogMixin from '../mixins/DialogMixin';
export default {
  name : 'TourDialog',
  mixins: [DialogMixin],
  data(){
      return {
      }
  },
  methods: {
      startTour() {
          this.emitResponse('ok');
      },
      async show(data){
        return DialogMixin.methods.show.call(this, data);
      },
  }
}
</script>