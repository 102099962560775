<template>
  <Toolbar class="topbar">
    <template #left>
      <img class="logo" src="/images/thinfinity.svg">
    </template>
    <template #right>
      <AvatarMenu ref="avatarMenu" :user="user" :options="options" @select="process"/>
    </template>
  </Toolbar>
  <ChangePasswordDialog ref="changePasswordDialog"></ChangePasswordDialog>
  <beta-version corner="top left"/>
</template>
<script>
import { apiConfig } from '../config/backend-api';
import { authService } from '../services/auth.service';
import AvatarMenu from './AvatarMenu.vue';
import ChangePasswordDialog from './ChangePasswordDialog.vue';
import BetaVersion from './BetaVersion.vue';

export default {
  name:  'TopBar',
  components: { AvatarMenu, ChangePasswordDialog, BetaVersion },
  data() {
    return {
      options: [
        {
          type: AvatarMenu.AvatarItemTypes.CUSTOM,
          components: [
            { componentName: "span", label: this.$t('avatar.accountId'), "class": "menu-item-part label" },
            { componentName: "b", label: this.$store.state.auth.user.accountId, "class": "menu-item-part value" },
          ]
        },
        {
          type: AvatarMenu.AvatarItemTypes.SEPARATOR
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label:'Change password',
          icon:'thin-key'
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label: 'Help',
          icon: 'thin-question'
        },
        {
          type: AvatarMenu.AvatarItemTypes.SEPARATOR
        },
        {
          type: AvatarMenu.AvatarItemTypes.LINK,
          label:'Logout',
          icon:'thin-logout'
        },
      ],
      actions: [
        null, null,
        this.changePassword,
        this.openHelp,
        null,
        this.logout
      ]
    }
  },
  computed: {
    //trialExpired: function() { return this.$store.state.trialExpired; },
    trialExpired: function() { return this.$store.state.trial.trialDays > 0; },
    user: function() { return this.$store.state.auth.user; },
  },
  methods: {
    logout(){
      authService.logout();
    },
    changePassword() {
      this.$refs.changePasswordDialog.show();
    },
    openHelp() {
      let helpURL = apiConfig.getConfigUrlValue("help");// || "https://workspaces-docs.cybelesoft.com/";
      window.open(helpURL, "help");
    },
    userInitials() {
      let i = "?";
      if (this.user && this.user.lastName != undefined) {
        i = (this.user.firstName[0] + this.user.lastName[0]).toUpperCase();
      }
      return i;
    },
    process: function(value){
      try {
        let action = this.actions[this.options.findIndex((opt) => opt.label == value)];
        if (action && typeof action == "function") {
          action();
        }
      } catch(e) {
        console.log(e.message);
      }
    },
  },
};
</script>