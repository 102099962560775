<template>
  <Dialog :modal="true" @hide="onDialogHide" v-model:visible="internal.show" style="min-width:20vw">
    <template #header>
      <span class="p-dialog-title">{{ title }}</span>
    </template>
    <div class="p-grid">
      <div class="p-col-3 popup-icon">
        <i :class="icon" />
      </div>
      <div class="p-col popup-body">
        <p :key="elem" v-for="(elem) in messageLines" class="p-dialog-message">{{ elem }}</p>
      </div>
    </div>
    <template #footer>
      <Button ref="okBtn" :label="$t('common.ok_button')" @click="acceptDialog()" :class="acceptClass"/>
    </template>
  </Dialog>
</template>

<script>
import DialogMixin from '../mixins/DialogMixin';
export default {
  name : 'CommonDialog',
  mixins: [DialogMixin],
  data(){
      return {
        title: "",
        message: "",
        icon: "",
        acceptClass: "",
        rejectClass: ""
      }
  },
  computed: {
    "messageLines": function() {return (this.message instanceof Array) ? this.message: [this.message] }
  },
  methods: {
      acceptDialog() {
        this.internal.show = false;
      },
      onDialogHide() {
        this.emitResponse("ok");
      },
      async show(data){
        Object.assign(this, data);
        return DialogMixin.methods.show.call(this, data);
      },
  }
}
</script>