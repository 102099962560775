export const ErrorHelper = {
  getErrorMessage,
  getErrorCode,
  getLinesFrom,
  RESPCODE_NOERROR: 0,
  RESPCODE_NOTFOUND: -1,
  RESPCODE_ALREADYEXISTS: -2,
  RESPCODE_PASSWORD_DOES_NOT_MATCH_RULE: -3,
  RESPCODE_NO_PROFILES_AVAILABLE: -5,
  RESPCODE_INVALIDCREDENTIALS: -6,
  RESPCODE_CREDENTIALSEXPIRED: -7,
  RESPCODE_INVALIDCONTENT: -9,
  RESPCODE_NEEDRENEWTOKEN: -10,
  RESPCODE_INVALIDTOKEN: -11,
  RESPCODE_TOKENRENEWALEXPIRED: -12,
  RESPCODE_USERNOTVERIFIED: -13,
  RESPCODE_USERISNOTINPENDINGSTATE: -14,
  RESPCODE_PENDINGVERIFICATIONEXPIRED: -15,
  RESPCODE_DEFAULTGROUPALREADYEXISTS: -17,
  RESPCODE_FORBIDDEN: -18,
  RESPCODE_INVALIDACCOUNT: -19,
  RESPCODE_CANNOTADDMEMBERTODEFAULTGROUP: -20,
  RESPCODE_CANNOTSENDMAIL: -21,
  RESPCODE_HUBSPOTCREATECONTACTERROR: -22,
  RESPCODE_USERSLIMITEXCEDED: -31,
  RESPCODE_LICENSEEXPIRED: -32,
  RESPCODE_SERVICELIMITEXCEDED: -33,
  RESPCODE_INVALID_SUBDOMAIN: -41,
  RESPCODE_INVALID_PRIVATEGATEWAY: -42,
  RESPCODE_STATUS_NONE: -50,
  RESPCODE_STATUS_PENDING: -51,
  RESPCODE_STATUS_INACTIVE: -52,
  RESPCODE_STATUS_LOCKED: -53,
  RESPCODE_STATUS_NOT_DEFINED: -54,
  RESPCODE_RESETPASWORD_TOKEN_ESPIRED: -55,
  RESPCODE_INVALID_ROLE: -56,
  RESPCODE_INTERNALSERVERERROR: -500,
};

function getErrorMessage(error) {
  let oe = { error };
  try {
    let re = oe.error.response.data.error;
    if (Array.isArray(re)) {
      let ae = '';
      re.forEach((element) => {
        ae = ae + element.code + ' ' + element.message;
      });
      return ae;
    } else {
      return re.message;
    }
  } catch (e) {
    return oe.error.message;
  }
}

function getErrorCode(error) {
  let oe = { error };
  try {
    let re = oe.error.response.data.error;
    if (Array.isArray(re)) {
      return re[0].code;
    } else {
      return re.code;
    }
  } catch (e) {
    return 500;
  }
}

function getLinesFrom(content, separator) {
  return (content || '').split(separator);
}
