import roles from '../enums/roles';
import { store } from '../store';

export const HBChat = {
  configure,
  load,
  unLoad,
};

function configure(hubSpotChatConfig) {
  /* Hay que emular este codigo
  <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5279927.js"></script>
  */
  store.commit('setChat', hubSpotChatConfig);
  let script = document.createElement('script', { id: 'hs-script-loader' });
  script.src = hubSpotChatConfig.id;
  script.defer = true;
  script.async = true;
  script.addEventListener('load', () => {
    const onConversationsAPIReady = () => {
      window.hsConversationsSettings = {
        loadImmediately: false,
      };
      store.commit('loadChat', true);
      HBChat.load();
    };
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  });
  document.body.append(script);
}

function unLoad() {
  store.commit('loadChat', false);
  if (window.HubSpotConversations)
    window.HubSpotConversations.clear({ resetWidget: true });
}

function load() {
  if (store.state.auth.logged) {
    if (store.state.hubSpotChatConfig.loadAfterLogin) {
      if (store.state.auth.user.role === roles.USER) {
        if (store.state.hubSpotChatConfig.visibleForRoleUser) {
          window.HubSpotConversations.widget.load();
        } else {
          unLoad();
        }
      } else {
        window.HubSpotConversations.widget.load();
      }
    } else {
      unLoad();
    }
  } else {
    window.HubSpotConversations.widget.load();
  }
}
