import { apiConfig } from '../config/backend-api';
import axios from 'axios';
import { store } from '../store';
import { userData } from '../models/user';

export const adminService = {
  createUser,
  updateUser,
  deleteUser,
  getUsers,
  forgotPassword,
  resetPassword,
  getServices,
  downloadInstaller,
  cancelDownload,
  updateService,
  deleteService,
  updateAdminProfile,
  getProfile,
//  checkTrialExpiration,
  changePassword,
  pingService,
  getCheckoutSession,
  getCustomerPortalSession,
  reset2FA,
  setUserTour,
  getUserData,
  refreshUserData,
  parseUserData
};

let downloadToken = null;

function headerWithToken() {
  let token = '';
  if (store.state.auth.user) {
    token = store.state.auth.token ?? token;
  }
  let headers = {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${token}`,
  };
  return headers;
}

async function downloadInstaller(refressFunc) {
  downloadToken = axios.CancelToken.source();
  return await axios({
    url: apiConfig.downloadUrl,
    // TODO: url final
    method: 'GET',
    cancelToken: downloadToken.token,
    responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      refressFunc(percentage);
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.setAttribute('download', 'Thinfinity.Workspaces.Setup.exe');
    document.body.appendChild(anchor);
    anchor.click();
  });
}

function cancelDownload() {
  if (downloadToken !== null) {
    downloadToken.cancel('Operation canceled by the user.');
  }
}

function forgotPassword(accountId, email) {
  return axios.post(
    `${apiConfig.baseUrl}/auth/forgotpassword`,
    { accountId, email },
    { headers: headerWithToken() }
  );
}

function resetPassword(usertoken, password) {
  return axios.post(
    `${apiConfig.baseUrl}/auth/resetpassword`,
    { usertoken, password },
    { headers: headerWithToken() }
  );
}

function changePassword(currentPassword, newPassword) {
  return axios.post(
    `${apiConfig.baseUrl}/auth/changepassword`,
    { newPassword: newPassword, oldPassword: currentPassword },
    { headers: headerWithToken() }
  );
}

function createUser(user) {
  user.accountId = store.state.auth.user.accountId;
  return axios.post(`${apiConfig.baseUrl}/users/`, user, {
    headers: headerWithToken(),
  });
}

function updateUser(user) {
  return axios.put(`${apiConfig.baseUrl}/users/`, user, {
    headers: headerWithToken(),
  });
}

function deleteUser(userId) {
  return axios.delete(`${apiConfig.baseUrl}/users/${userId}`, {
    headers: headerWithToken(),
  });
}

function getUsers({ config = {} } = {}) {
  return axios.get(`${apiConfig.baseUrl}/users/`, {
    headers: headerWithToken(),
    ...(config?.ajaxOptions ?? {})
  });
}

function getServices(serviceId, { config = {} } = {}) {
  let url;
  if (serviceId == null) {
    url = `${apiConfig.baseUrl}/services/`;
  } else {
    url = `${apiConfig.baseUrl}/services/${serviceId}`;
  }
  return axios.get(url, { headers: headerWithToken(), ...(config?.ajaxOptions ?? {}) });
}

function updateService(serviceIsDown, service) {
  let updateservice = { serviceIsDown, service: service };
  let url = `${apiConfig.baseUrl}/services/${service.id}`;
  return axios.put(url, updateservice, { headers: headerWithToken() });
}

function deleteService(serviceId) {
  let url = `${apiConfig.baseUrl}/services/${serviceId}`;
  return axios.delete(url, { headers: headerWithToken() });
}

function updateAdminProfile(user) {
  user.userId = store.state.auth.user.email;
  return axios.put(`${apiConfig.baseUrl}/admin/profile`, user, {
    headers: headerWithToken(),
  });
}

function pingService(url, { config = {} } = {}) {
  let headers = { 'Content-Type': 'application/json' };
  return axios.get(url + '/ping', {
    headers: headers,
    ...(config?.ajaxOptions ?? {})
  });
}

function getProfile() {
  let url = `${apiConfig.baseUrl}/admin/profile`;
  return axios.get(url, {
    headers: headerWithToken(),
  });
}

/*function checkTrialExpiration() {
  let url = `${apiConfig.baseUrl}/admin/checktrialexpiration`;
  axios
    .get(url, {
      headers: headerWithToken(),
    })
    .then((response) => {
      let r = { response };
      store.commit('trialExpired', r.response.data.data.trialexpires);
    })
    .catch(() => {
      store.commit('trialExpired', true);
    });
}
*/

function getCheckoutSession(priceid, customerMode) {
  return axios.get(
    `${apiConfig.baseUrl}/stripe/checkout/createsession/${priceid}/${customerMode}`,
    {
      headers: headerWithToken(),
    }
  );
}

function getCustomerPortalSession() {
  return axios.get(`${apiConfig.baseUrl}/stripe/portal/createsession/`, {
    headers: headerWithToken(),
  });
}

function reset2FA(serviceId, emailOrUserId) {
  return axios.post(
    `${apiConfig.baseUrl}/auth/reset2fa/${serviceId}`,
    { email: emailOrUserId },
    { headers: headerWithToken() }
  );
}

function setUserTour(val = true, { config = {} } = {}) {
  return axios.put(`${apiConfig.baseUrl}/settourstate/`, { "endTour": val }, { headers: headerWithToken(), ...(config?.ajaxOptions ?? {}) }).then(() => {
    store.state.auth.user.endTour = val;
  });
}

function parseUserData(res) {
  return {
    ...userData,
    accountId: res.accountid,
    email: res.email,
    lastName: res.user.lastName,
    firstName: res.user.firstName,
    role: res.level,
    endTour: !res.user.endTour === false
  };
}

async function refreshUserData() {
  let user = await getUserData();
  user = await user?.data?.data;
  if (user) {
    user = parseUserData(user);
    store.dispatch('login', { user });
  } else {
    throw new Error(400);
  }
}

function getUserData({ config = {} } = {}) {
  return axios.get( `${apiConfig.baseUrl}/auth/userdata`, {
    headers: headerWithToken(),
    ...(config?.ajaxOptions ?? {})
  });
}