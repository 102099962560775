import router from "../../router"

export default {
    namespaced: true,
    state: () => {
        let tourState = {
            steps: [
                {
                    target: '[data-v-step="workspaces"]',
                    content: 'Workspaces: this section displays all your available workspaces, you can setup a new one, edit or remove existing ones.',
                    params: {
                        placement: 'right'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "workspaces" });
                            resolve();
                        });
                    },
                },
                {
                    target: '[data-v-step="workspaces-installer"]',
                    content: 'Download the installer to setup a new workspace.',
                    params: {
                        placement: 'bottom'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "workspaces" }).then(() => {
                                resolve();
                            });
                        });
                    },
                    filter: navigator.userAgent.indexOf('Windows ') != -1
                },
                {
                    target: '[data-v-step="users"]',
                    content: 'Users: Here you can manage users for your workspaces. You can create a new one, edit or remove existing ones.',
                    params: {
                        placement: 'right'
                    },
                    before: () => {
                        return new Promise((resolve) => {
                            router.push({ name: "users" });
                            resolve();
                        });
                    },
                }
            ]
        };

        tourState.steps = tourState.steps.filter(t => {
            return t.filter ?? true
        });

        return tourState;
    },
}