<template>
  <div class="sidemenu">
    <div
      :key="name" v-for="(value, name) in visibleOptions"
      class="item" :class="(value.id == activeOption ? 'active': '')"
      :id="value.id" @click="navigateTo(value.id)"
      :data-v-step="`${value.id}`"
    >
      <div class="item-content">
        <span class="icon" :class="value.icon"></span>
        <span class="label">{{value.label}}</span>
      </div>
      <span class="selector"></span>
    </div>
  </div>
  <!-- <v-tour name="myTour" :steps="steps"></v-tour> -->
</template>

<script>
export default {
  name: "SideMenuBar",
  data() {
    return {
      activeOption: null,
      steps: [
        {
          target: '[data-v-step="users"]',
          content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
          params: {
            placement: 'right' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="workspaces"]',
          content: 'Try it, you\'ll love it!<br>You can put HTML in the steps and completely customize the DOM to suit your needs.',
          params: {
            placement: 'right' // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        }
      ]
    }
  },
  props: [ "options", "default" ],
  computed: {
    visibleOptions() { return this.options.filter((e) => e.visible) }
  },
  mounted() {
    if (!this.activeOption && this.default) {
      this.navigateTo(this.default);
    }
  },
  methods: {
    navigateTo(value) {
      let option = this.options.find((e) => { return e.id == value});
      if (option) {
        if (option.action != undefined) {
          this.activeOption = option.id;
          option.action();
        } else if (option.route != undefined) {
          this.$router.push(option.route);
        }
      }
    }
  },
  watch:{
    $route: {
      handler(to) {
        if (to && to.name) {
          let value = to.name;
          let option = this.options.find((e) => { return e.id == value});
          if (option?.route != undefined) {
            this.activeOption = option.id;
          }
        }
      },
      immediate: true,
    }
  }
};
</script>