<template>
  <Password>
    <template #content>
      <Divider />
      <p class="p-mt-2">{{ $t('register.suggestions') }}</p>
      <ul class="p-pl-2 p-ml-2 p-mt-0">
        <li>
          {{
            $t('passwordComplexity.minlen', {
              value: this.passwordComplexity.minlen,
            })
          }}
        </li>
        <li>
          {{
            $t('passwordComplexity.maxlen', {
              value: this.passwordComplexity.maxlen,
            })
          }}
        </li>
        <li v-if="this.passwordComplexity.mustContainUppercase">
          {{ $t('passwordComplexity.mustContainUppercase') }}
        </li>
        <li v-if="this.passwordComplexity.mustContainLowercase">
          {{ $t('passwordComplexity.mustContainLowercase') }}
        </li>
        <li v-if="this.passwordComplexity.mustContainNumbers">
          {{ $t('passwordComplexity.mustContainNumbers') }}
        </li>
        <li v-if="this.passwordComplexity.mustContainNonAlphanumeric">
          {{ $t('passwordComplexity.mustContainNonAlphanumeric') }}
        </li>
      </ul>
    </template>
  </Password>
</template>

<script>

export default {
  name: 'PasswordComplexity',
  data() {
      return {
        passwordComplexity : {},
    }
  },
  mounted() {
    this. passwordComplexity = this.$store.state.passwordComplexity;    
  }

};
</script>
