<template>
    <span class="fielderrormsg" :class="extraClass">{{ message || '&nbsp;' }}</span>
</template>
<script>
export default {
    name: "FieldError",
    tag: "field-error",
    props: ["value", "class"],
    computed:{
        'message': function() {
            let output = "";
            if (this.value) {
                if (typeof this.value == "string")
                    output = this.value;
                else
                    output = (this.value && this.value["$error"]) ? this.value["$errors"][0]["$message"] : ""; 
            }
            return output;            
        },
        'extraClass': function() { return this.class || "" }
    }
}
</script>
