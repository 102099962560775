<template>
  <div>
    <div class="vld-parent">
      <ConfirmDialog ref="confirmDlg"></ConfirmDialog>
      <div class="p-mt-6 p-mr-6 p-ml-6">
        <div class="header-template">
          <div class="p-grid">
            <div class="p-col-6">
              <h1 class="p-mt-0 p-mb-0 mobile-hide">{{ $t('services.title') }}</h1>
            </div>
            <div class="p-col-6 p-text-right" v-if="isWindows && !readOnly">
              <downloader
                data-v-step="workspaces-installer"
                v-if="!readOnly"
                @requestDownload="askForDownload"
                @requestCancel="askForCancel"
                :progress="progressValue"
                :downloading="downloading"
                :value="$t('services.downloadButton')"
                :cancelable="true"
              />
            </div>
          </div>
        </div>
        <resizer @resize="onResize"></resizer>
        <div v-if="compactMode == true">
          <compact-list class="mobile-table" :items="services" :isloading="loadingServices" :filtrable="false" @edit="editService" @delete="confirmDeleteService">
            <template #loading>
              <GridSkeleton v-for="inx in 2" :key="inx" cols="3"></GridSkeleton>
              <GridSkeleton
                v-for="inx in 5"
                :key="inx"
                cols="3"
                :className="`p-opacity-${6 - inx}`"
              ></GridSkeleton>
            </template>
            <template #empty>
              <div class="p-d-flex p-jc-center p-py-4">
                <div class="p-text-center p-lineheight-2">
                  <span>{{ $t('workspaces.no_workspaces_found.message') }}</span>
                  <br />
                  <div v-if="isWindows && !readOnly">
                    <span>{{ $t('workspaces.no_workspaces_found.download_installer_1') }}</span>
                    <span class="anchor" @click="askForDownload">{{$t('workspaces.no_workspaces_found.download_installer_2') }}</span>
                    <span>{{ $t('workspaces.no_workspaces_found.download_installer_3') }}</span>
                  </div>
                </div>
              </div>
            </template>
            <template #noresults>
              <div class="p-d-flex p-jc-center p-py-4">
                <div class="p-text-center p-lineheight-2">
                  <span>{{ $t('workspaces.no_workspaces_match') }}</span>
                </div>
              </div>
          </template>
          <template #content="slotProps">
            <div>
              <i class="pi pi-circle-on" :class="{'error':(slotProps.item.state === 1)}" ></i>
              <b>{{slotProps.item.name}}</b> <span class="item-icon" :class="getThinClass(slotProps.item.type)" v-tooltip.top="slotProps.item.type"></span>
            </div>
            <div>
                <span v-if="slotProps.item.state === 1" class="list-url disabled">{{ slotProps.item.urlAddress }}</span>
                <a v-else :href="slotProps.item.urlAddress" target="_blank">{{ slotProps.item.urlAddress }}</a>
            </div>
            <div>
                <span>{{$t('services.machine')}}:</span> {{slotProps.item.machine}}
            </div>
            <div>
                <span>{{$t('services.users')}}:</span> {{slotProps.item.license.limits.users}}
            </div>
          </template>
        </compact-list>
      </div>
      <div v-else class="p-grid">
        <div class="p-col-12">
          <keep-alive>
            <DataTable
              :value="services"
              :loading="loadingServices"
              loadingIcon=""
              dataKey="id"
              :paginator="false"
              :rows="20"
              columnResizeMode="fit"
              editMode="cell"
              responsiveLayout="scroll"
              sortField="name"
              :sortOrder="1"
            >
              <template #header></template>
              <template #loading>
                <div class="loading">
                  <GridSkeleton v-for="inx in 2" :key="inx" cols="6"></GridSkeleton>
                  <GridSkeleton v-for="inx in 5" :key="inx" cols="6" :className="`p-opacity-${6 - inx}`"></GridSkeleton>
                </div>
              </template>
              <template #empty>
                <div class="p-d-flex p-jc-center p-py-4">
                  <div class="p-text-center p-lineheight-2">
                    <span>{{ $t('workspaces.no_workspaces_found.message') }}</span>
                    <br>
                    <div v-if="isWindows && !readOnly">
                      <span>{{ $t('workspaces.no_workspaces_found.download_installer_1') }}</span>
                      <span class="anchor" @click="askForDownload">{{
                        $t('workspaces.no_workspaces_found.download_installer_2')
                      }}</span>
                      <span>{{ $t('workspaces.no_workspaces_found.download_installer_3') }}</span>
                    </div>
                  </div>
                </div>
                </template>
                <Column
                  field="state"
                  :header="$t('services.state')"
                  :sortable="true"
                  class="status"
                >
                  <template #body="slotProps">
                    <i class="pi pi-circle-on" v-if="slotProps.data.state === 0"></i>
                    <i class="pi pi-circle-on error" v-if="slotProps.data.state === 1"></i>
                  </template>
                </Column>
                <Column
                  field="name"
                  :header="$t('services.name')"
                  :sortable="true"
                  class="editable-cells-table"
                ></Column>
                <Column field="machine" :header="$t('services.machine')" :sortable="true"></Column>
                <Column
                  field="license.limits.users"
                  :header="$t('services.users')"
                  class="users-cell"
                  :sortable="true"
                ></Column>
                <Column
                  field="type"
                  :header="$t('services.type')"
                  class="type-cell"
                  :sortable="true"
                >
                  <template #body="slotProps">
                    <div
                      class="item-icon"
                      :class="getThinClass(slotProps.data.type)"
                      v-tooltip.top="slotProps.data.type"
                    ></div>
                  </template>
                </Column>
                <Column field="urlAddress" header="Url" :sortable="true">
                  <template #body="slotProps">
                    <span v-if="slotProps.data.state === 1" class="list-url disabled">
                      {{ slotProps.data.urlAddress }}
                    </span>
                    <a
                      v-if="slotProps.data.state === 0"
                      :href="slotProps.data.urlAddress"
                      target="_blank"
                      >{{ slotProps.data.urlAddress }}</a
                    >
                  </template>
                </Column>
                <Column
                  v-if="!readOnly"
                  field="features"
                  :header="$t('services.actions')"
                  :sortable="false"
                  headerStyle="width:15%"
                >
                  <template #body="slotProps">
                    <Button
                      icon="thin-edit"
                      v-tooltip.top="$t('users.edit')"
                      class="p-button-link"
                      @click="editService(slotProps.data)"
                    />
                    <Button
                      icon="thin-trash"
                      v-tooltip.top="$t('users.delete')"
                      class="p-button-link"
                      @click="confirmDeleteService(slotProps.data)"
                    />
                  </template>
                </Column>
              </DataTable>
            </keep-alive>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="displayServiceDialog"
    :header="`Configure ${service.name}`"
    :modal="true"
    :breakpoints="{ '48rem': '100vw' }"
    :style="{ width: '48rem' }"
    @show="updateMessage"
  >
    <transition-group name="p-message" tag="div">
      <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{
        msg.content
      }}</Message>
    </transition-group>

    <div class="configureServiceDlg">
      <Accordion :multiple="false" :activeIndex="0" class="section" :lazy="true">
        <AccordionTab :header="$t('configureService.name')">
          <div class="p-grid">
            <div class="p-col-12 p-field">
              {{ $t('configureService.generalDescription') }}
            </div>
            <div class="p-col p-field">
              <label for="servicename">{{ $t('configureService.nameTitle') }}:</label>
            </div>
            <div class="p-col-9 p-field p-right">
              <InputText
                id="servicename"
                v-model="service.name"
                style="width:100%"
                autofocus
              ></InputText>
              <field-error :value="v$.service.name" />
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="subdomain">{{ $t('configureService.subdomain') }}:</label>
            </div>
            <div class="p-col p-col-9 p-field p-right">
              <InputText
                id="subdomain"
                v-model="service.subdomain"
                :placeholder="$t('configureService.subdomainLabel')"
                :disabled="featuresDisabled"
                class="p-col-5"
              ></InputText
              ><i class="p-inputtext p-component domainprefix">-{{ accountId }}.{{ domain }}</i>
              <field-error :value="v$.service.subdomain" />
            </div>
          </div>
          <div class="p-grid">
            <div class="p-col p-field">
              <label for="userlimits">{{ $t('configureService.usersTitle') }}:</label>
            </div>
            <div class="p-col-9 p-field">
              <InputNumber
                :min="1"
                :max="maxUsers"
                v-model="service.license.limits.users"
                :disabled="featuresDisabled"
              ></InputNumber>
            </div>
          </div>
        </AccordionTab>

        <AccordionTab :header="$t('configureService.authenticationsTitle')">
          <!--div class="p-grid">
            <div class="p-col-12 p-field-title">
              {{ $t('configureService.workspaceTitle') }}
            </div>
            <div class="p-col-10 p-field-desc">
              {{ $t('configureService.workspaceDescription') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-if="service.features.authentications && service.features.authentications.workspace"
                v-model="service.features.authentications.workspace.enabled"
                :disabled="featuresDisabled"
              ></InputSwitch>
            </div>
          </div>
          <br /-->
          <div class="p-grid">
            <div class="p-col-12 p-field">
              {{ $t('configureService.authenticationDescription') }}
            </div>
            <div class="p-col-10 p-field">
              {{ $t('configureService.authenticationOthersGoogle') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.authentications.google"></InputSwitch>
            </div>
            <div class="p-col-10 p-field">
              {{ $t('configureService.authenticationOthersMicrosoft') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.authentications.microsoft"></InputSwitch>
            </div>
            <div class="p-col-10 p-field">
              {{ $t('configureService.authenticationOthersLinkedin') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.authentications.linkedin"></InputSwitch>
            </div>
            <div class="p-col-10 p-field">
              {{ $t('configureService.authenticationOthersFacebook') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.authentications.facebook"></InputSwitch>
            </div>
            <div class="p-col-10 p-field">
              {{ $t('configureService.secondFactorTitle') }}
            </div>
            <span class="p-col p-field p-text-right">
              <InputSwitch
                v-if="service.features.authentications"
                v-model="service.features.authentications.secondFactor.enabled"
                :disabled="featuresDisabled"
              ></InputSwitch>
            </span>
            <div class="p-col-10 p-field">
              {{ $t('configureService.activeDirectoryTitle') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch
                v-if="
                  service.features.authentications &&
                    service.features.authentications.activeDirectory
                "
                v-model="service.features.authentications.activeDirectory.enabled"
                :disabled="
                  service.features.authentications.activeDirectory.available === false ||
                    featuresDisabled === true
                "
              ></InputSwitch>
            </div>
            <div
              class="p-col-10 p-field"
              v-if="service.features.authentications.activeDirectory.enabled"
            >
              {{ $t('configureService.restrictAccessLabel') }}
            </div>
            <div
              class="p-col-10 p-field"
              v-if="service.features.authentications.activeDirectory.enabled"
            >
              <InputText
                id="restrictedgroups"
                v-model="service.features.authentications.activeDirectory.restrictedGroups"
                style="width:100%"
              ></InputText>
            </div>
            <!-- <div class="p-col-10 p-field">
              {{ $t('configureService.advancedAuthenticationTitle') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch
                v-model="service.features.advancedAuthentication"
                :disabled="featuresDisabled"
              ></InputSwitch>
            </div> -->
          </div>
        </AccordionTab>

        <AccordionTab :header="$t('configureService.otherConfiguration')">
          <!--div class="p-grid">
            <div class="p-col-12 p-field">
              {{ $t('configureService.otherConfigurationDescription') }}
            </div>
          </div-->
          <div
            v-if="service.features && service.features.scrapers && service.type === 'network'"
            class="p-grid"
          >
            <!--div class="p-col-10">
              <div class="p-grid">
                <div class="p-col">
                  {{ $t('configureService.scrapersDescription') }}
                </div>
              </div>
            </div-->
            <div class="p-col-10 p-field">
              {{ $t('configureService.privateProfilesDescription') }}
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch
                v-model="service.features.privateProfiles"
                :disabled="featuresDisabled"
              ></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.rdp?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersRDPLabel') }}
            </div>
            <div v-if="service.features.scrapers.rdp?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.rdp.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.vnc?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersVNCLabel') }}
            </div>
            <div v-if="service.features.scrapers.vnc?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.vnc.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.rfb?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersRFBLabel') }}
            </div>
            <div v-if="service.features.scrapers.rfb?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.rfb.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.zsc?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersSSHLabel') }}
            </div>
            <div v-if="service.features.scrapers.zsc?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.zsc.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.rpxy?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersRPXYLabel') }}
            </div>
            <div
              v-if="service.features.scrapers.rpxy?.available"
              class="p-col p-field p-text-right"
            >
              <InputSwitch v-model="service.features.scrapers.rpxy.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.dav?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersDAVLabel') }}
            </div>
            <div v-if="service.features.scrapers.dav?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.dav.enabled"></InputSwitch>
            </div>
            <div v-if="service.features.scrapers.fmx?.available" class="p-col-10 p-field">
              {{ $t('configureService.scrapersFMXLabel') }}
            </div>
            <div v-if="service.features.scrapers.fmx?.available" class="p-col p-field p-text-right">
              <InputSwitch v-model="service.features.scrapers.fmx.enabled"></InputSwitch>
            </div>
          </div>
          <br />
          <div class="p-grid">
            <div class="p-col-10">
              <div class="p-grid">
                <div class="p-col p-pb-5">
                  {{ $t('configureService.privateGatewayTitle') }}
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col p-field">
                  <label>{{ $t('configureService.privateGatewayNetworkIdLabel') }}:</label>
                </div>
                <div class="p-col-9 p-field">
                  <InputText
                    v-model="service.features.privateGateway.networkId"
                    :disabled="
                      featuresDisabled || service.features.privateGateway.enabled === false
                    "
                    style="width:100%"
                  ></InputText>
                  <field-error :value="v$.service.features.privateGateway.networkId" />
                </div>
              </div>
              <div class="p-grid">
                <div class="p-col p-field">
                  <label>{{ $t('configureService.privateGatewayURLLabel') }}:</label>
                </div>
                <div class="p-col-9 p-field">
                  <InputText
                    v-model="service.features.privateGateway.url"
                    :disabled="
                      featuresDisabled || service.features.privateGateway.enabled === false
                    "
                    style="width:100%"
                  ></InputText>
                  <field-error :value="v$.service.features.privateGateway.url" />
                </div>
              </div>
            </div>
            <div class="p-col p-field p-text-right">
              <InputSwitch
                v-model="service.features.privateGateway.enabled"
                :disabled="featuresDisabled"
              ></InputSwitch>
            </div>
          </div>
        </AccordionTab>
        <AccordionTab
          v-if="service.features.authentications.secondFactor.enabled"
          :header="$t('configureService.reset2FATitle')"
        >
          <div class="p-grid">
            <div class="p-col p-col-9 p-field">
              <div class="p-grid">
                <div class="p-col p-field">
                  <label for="reset2FA">{{ $t('configureService.reset2FAEmail') }}:</label>
                </div>
                <div class="p-col p-col-9 p-field">
                  <InputText
                    id="reset2FA"
                    v-model="reset2FAEmailOrUserId"
                    style="width:100%"
                  ></InputText>
                  <field-error :value="v$.reset2FAEmailOrUserId" />
                </div>
              </div>
            </div>
            <div class="p-col p-col-3 p-field p-text-center">
              <Button :label="$t('configureService.reset2FAButton')" @click="reset2FA()"></Button>
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    </div>
    <template #footer>
      <div>
        <Button
          ref="saveBtn"
          class="p-button-primary"
          label="Save"
          @click.prevent="updateService"
        />
        <Button
          ref="cancelBtn"
          label="Cancel"
          class="p-button-secondary"
          @click="closeServiceDialog"
        />
      </div>
    </template>
  </Dialog>

  <CommonDialog ref="dialog"></CommonDialog>
</template>

<script>
import { adminService } from '../services/admin.service';
import { ErrorHelper } from '../helpers/ErrorHelper';
import { ServiceModel } from '../models/service';
import Downloader from '../components/Downloader.vue';
import GridSkeleton from '../components/GridSkeleton.vue';
import { FilterMatchMode } from 'primevue/api';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, and, url, helpers } from '@vuelidate/validators';
import FieldError from '../components/FieldError.vue';
import { mapState } from 'vuex';
import { apiConfig } from '../config/backend-api';
import CommonDialog from '../components/CommonDialog.vue';
import CompactList from '../components/CompactList.vue';
import Resizer from '../components/Resizer.vue';

const COMPACT_LIMIT = 800;
const MAX_USERS = 65535;

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: { Accordion, AccordionTab, FieldError, Downloader, GridSkeleton, CommonDialog, CompactList, Resizer },
  name: 'Workspaces',
  props: {
    readOnly: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      reset2FAEmailOrUserId: '',
      validateReset2FAEmailOrUserId: true,
      successMessage: '',
      changingAuthentications: false,
      cancelPing: false,
      messages: [],
      updatingService: false,
      readyToPing: false,
      featuresDisabled: false,
      serviceIsDown: true,
      headerMessage: '',
      errorMessage: '',
      displayServiceDialog: false,
      services: [],
      loadingServices: true,
      service: ServiceModel,
      originalValue: '',
      domain: apiConfig.domain,
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      isWindows: navigator.userAgent.indexOf('Windows ') !== -1,
      width: window.innerWidth,
    };
  },
  computed: {
    accountId() {
      return this.$store.state.auth.user.accountId;
    },
    maxUsers() {
      return MAX_USERS;
    },
    ...mapState({
      progressValue: (state) => state.installerDownload.progress,
      downloading: (state) => state.installerDownload.started,
    }),
    compactMode() {
      return this.width <= COMPACT_LIMIT;
    },
  },
  validations() {
    const httpsUrl = (value) => !helpers.req(value) || value.toLowerCase().indexOf('https') === 0;
    const validSubdomain = helpers.regex(/^[a-zA-Z0-9]+[a-zA-Z0-9-]*[a-zA-Z0-9]+$/);
    return {
      reset2FAEmailOrUserId: {
        required: requiredIf(function() {
          return this.validateReset2FAEmailOrUserId;
        }),
      },
      service: {
        name: { required },
        subdomain: {
          required,
          subdomainOk: helpers.withMessage('Invalid subdomain', validSubdomain),
        },
        features: {
          privateGateway: {
            url: {
              shouldBeChecked: and(url, httpsUrl),
              required: requiredIf(function() {
                return this.service.features.privateGateway.enabled;
              }),
            },
            networkId: {
              required: requiredIf(function() {
                return this.service.features.privateGateway.enabled;
              }),
            },
          },
        },
      },
    };
  },
  mounted() {
    this.getServices({ config: { ajaxOptions: { ajaxState: false } } });
    this.initPing();
    if (this.$store.state.trial.canShow && this.$store.state.trial.daysLeft > 0) {
      window.setTimeout(() => {
        this.$toast.add({
          severity: 'warn',
          summary: this.$t('trialMessages.title'),
          group: "tmsgs",
          detail: this.$t('trialMessages.daysLeft', { daysTrialLeft: this.$store.state.trial.daysLeft}),
          life: 10000,
        });
        this.$store.commit('setCanShowTrialMessage', false);
      }, 100);
    }
  },
  unmounted() {
    this.readyToPing = false;
    this.cancelPing = true;
    clearTimeout(this.timerId);
  },
  methods: {
    reset2FA() {
      this.validateReset2FAEmailOrUserId = true;
      this.v$.reset2FAEmailOrUserId.$touch();
      if (this.v$.$invalid) {
        return;
      }
      adminService
        .reset2FA(this.service.id, this.reset2FAEmailOrUserId)
        .then(() => {
          this.showMessage(
            this.$t('configureService.reset2FASuccessMessage', { username: this.reset2FAEmailOrUserId }), () => { this.reset2FAEmailOrUserId = null; this.v$.reset2FAEmailOrUserId.$reset(); });
        })
        .catch((error) => {
          this.showError(ErrorHelper.getErrorMessage(error));
        });
    },
    formatUrl(data) {
      if (data.features.privateGateway.enabled) {
        return data.features.privateGateway.url;
      } else {
        return (
          'https://' +
          data.subdomain +
          '-' +
          this.$store.state.auth.user.accountId +
          '.' +
          data.domain
        );
      }
    },
    initPing() {
      let delay = 30000;
      this.timerId = setTimeout(
        function request(_this) {
          _this.getServices();
          if (!_this.cancelPing) {
            _this.$store.commit('ping', true);
            _this.timerId = setTimeout(request, delay, _this);
          }
        },
        delay,
        this
      );
    },
    pingServices({ config = {} } = {}) {
      if (!this.readyToPing || this.cancelPing) {
        return;
      }
      let context = this;
      this.count = 0;
      this.services.forEach((s) => {
        this.count++;
        let url = '';
        if (s.features.privateGateway.enabled) {
          url = s.domain;
          if (url.endsWith('/')) {
            url = url.substr(0, url.length - 1);
          }
        } else {
          url =
            'https://' + s.subdomain + '-' + this.$store.state.auth.user.accountId + '.' + s.domain;
        }
        adminService
          .pingService(url, { config })
          .then(() => {
            s.state = 0;
            context.downgradePing();
          })
          .catch(() => {
            context.downgradePing();
            s.state = 1;
            console.log('Ping Error to ' + url + ' ' + new Date().toLocaleTimeString());
          });
      });
    },
    downgradePing() {
      this.count--;
      if (this.count < 1) this.$store.commit('ping', false);
    },
    refreshProgressBar(value) {
      this.progressValue = value;
      if (value >= 100) {
        this.progressValue = 100;
      }
    },
    closeServiceDialog() {
      this.displayServiceDialog = false;
      this.readyToPing = true;
      this.updatingService = false;
    },
    disableMessage() {
      if (this.featuresDisabled) {
        this.$toast.add({ severity: 'warn', life: 5000 });
      }
    },
    updateMessage() {
      this.messages = [];
      if (this.featuresDisabled) {
        this.messages.push({
          severity: 'warn',
          content: this.$t('workspaces.serviceDown'),
          id: this.messages.length + 1,
        });
      }
    },
    updateService() {
      this.validateReset2FAEmailOrUserId = false;
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.readyToPing = false;
        if (this.service.features.privateGateway.enabled) {
          this.service.domain = this.service.features.privateGateway.url;
        } else {
          this.service.features.privateGateway.networkId = '';
          this.service.features.privateGateway.url = '';
          this.service.domain = this.domain;
        }
        this.serviceIsDown = this.service.state === 1;
        adminService
          .updateService(this.serviceIsDown, this.service)
          .then(() => {
            this.closeServiceDialog();
            this.getServices();
          })
          .catch((error) => {
            this.showError(ErrorHelper.getErrorMessage(error), () => { this.readyToPing = true });
          });
      }
    },
    editService(net) {
      this.updatingService = true;
      this.readyToPing = false;
      // this.featuresDisabled = net.state === 1;
      adminService
        .getServices(net.id)
        .then((response) => {
          let r = { response };
          if (r.response.data.data.service) {
            this.service = r.response.data.data.service;
            this.service.state = net.state;
            this.reset2FAEmailOrUserId = null;
            this.v$.$reset();
            this.displayServiceDialog = true;
          }
        })
        .catch((error) => {
          this.errorMessage = ErrorHelper.getErrorMessage(error);
          this.displayMessageDialog = true;
          this.readyToPing = true;
          this.updatingService = false;
        });
    },
    getServices({ config = {} } = {}) {
      if (!this.updatingService) {
        this.readyToPing = false;
        adminService
          .getServices(null, { config })
          .then((response) => {
            let r = { response };
            if (r.response.data.data.services) {
              let data = r.response.data.data.services.sort((a, b) => (a.name < b.name ? -1 : 1));
              data.forEach((elem) => {
                elem.urlAddress = this.formatUrl(elem);
                elem.state = this.services.find((t) => t.id == elem.id)?.state ?? 1;
              });
              this.services = data;
              this.loadingServices = false;
              this.readyToPing = true;
              this.pingServices({ config });
            }
          })
          .catch((error) => {
            if (console.warn) console.warn(ErrorHelper.getErrorMessage(error));
            this.readyToPing = true;
            this.pingServices();
          });
      }
    },
    confirmDeleteService(net) {
      this.readyToPing = false;
      this.$refs.confirmDlg
        .show({
          title: this.$t('workspaces.confirmDeleteService.title'),
          message: this.$t('workspaces.confirmDeleteService.message', { name: net.name }),
          icon: 'thin-question',
          acceptClass: 'p-button-danger',
        })
        .then((action) => {
          if (action === 'ok') {
            adminService
              .deleteService(net.id)
              .then(() => {
                this.getServices();
              })
              .catch((error) => {
                this.showError(ErrorHelper.getErrorMessage(error), function() { this.readyToPing = true; });
              });
          } else {
            this.readyToPing = true;
          }
        });
    },
    askForDownload() {
      this.$refs.confirmDlg
        .show({
          title: this.$t('workspaces.askForDownload.title'),
          message: this.$t('workspaces.askForDownload.message'),
          icon: 'thin-alert',
          acceptClass: 'p-button-primary',
          cancelClass: 'p-button-danger',
          focus: 'ok',
        })
        .then((action) => {
          if (action === 'ok') this.downloadInstaller();
        });
    },
    askForCancel() {
      this.$refs.confirmDlg
        .show({
          title: this.$t('workspaces.askForCancel.title'),
          message: this.$t('workspaces.askForCancel.message'),
          icon: 'thin-alert',
          acceptClass: 'p-button-danger',
          cancelClass: 'p-button-primary',
          focus: 'cancel',
        })
        .then((action) => {
          if (action === 'ok') {
            this.cancelDownload();
          }
        });
    },
    downloadInstaller() {
      this.$store.dispatch('downloadInstaller');
    },
    cancelDownload() {
      this.$store.dispatch('cancelDownload');
    },
    getThinClass(value) {
      let className = 'thin-';
      switch (value) {
        case 'app':
          className += 'tiles';
          break;
        case 'webapp':
          className += 'web';
          break;
        default:
          className += value;
      }
      return className;
    },
    onResize({ width }) {
      this.width = width;
    },
    showMessage(msg, callback) {
      this.$refs.dialog.show({
        title: this.$t('successDialog.title'),
        message: msg,
        icon: 'thin-check',
      }).then(() => {
        if (callback) callback();
      });
    },
    showError(errMsg, callback) {
      this.$refs.dialog.show({
        title: this.$t('errorDialog.title'),
        message: errMsg,
        icon: 'thin-cancel error',
      }).then(() => {
        if (callback) callback();
      });
    }
  }
};
</script>
<style scoped>
h3 {
  font-weight: normal;
  color: var(--primary-color);
}
</style>