import { createStore } from 'vuex';
import { ServiceModel } from '../models/service';
import { adminService } from '../services/admin.service';
import { userData } from '../models/user';
import { getTokenFromStorage } from '../services/auth.service';
import Tour from './_modules/tour';
import roles, { hasPermissions } from '../enums/roles';
import i18n from '../lang';
import router, { config as routerConfig } from '../router';

export const store = createStore({
  modules: {
    tour: Tour,
  },
  state: {
    trial: {
      canShow: false,
      daysLeft: 0,
    },
    hubSpotChatConfig: {
      loaded: false
    },
    hasSubscription: false,
    refreshProfile: 0,
    isPing: false,
    isDownload: false,
    //trialExpired: false,
    auth: {
      logged: false,
      token: getTokenFromStorage(),
      user: userData,
    },
    service: ServiceModel,
    passwordComplexity: {
      minlen: 0,
      maxlen: 0,
      mustContainUppercase: false,
      mustContainLowercase: false,
      mustContainNumbers: false,
      mustContainNonAlphanumeric: false,
    },
    ajaxState: false,
    installerDownload: {
      started: false,
      completed: false,
      progress: 0,
      error: false,
      errorMsg: null,
    },
    menu: {
      options: [
        {
          id: 'workspaces',
          icon: 'thin-globe',
          label: i18n.global.t('layout.workspaces'),
          route: '/admin/workspaces',
          visible: true,
        },
        {
          id: 'users',
          icon: 'thin-members',
          label: i18n.global.t('layout.users'),
          route: '/admin/users',
          visible: true,
          _showIf: (state) => hasPermissions(state.auth.user.role, roles.ADMIN),
        },
      ],
    },
  },
  mutations: {
    refreshProfile(state) {
      state.refreshProfile = state.refreshProfile + 1;
    },
    //trialExpired(state, value) {
    //  state.trialExpired = value;
    //},
    login(state, { user, token }) {
      state.auth.logged = true;
      state.auth.user = user;
      state.auth.token = token ?? state.auth.token;
    },
    logout(state) {
      state.auth.logged = false;
      state.auth.user = {};
    },
    pushService(state, service) {
      state.service = service;
    },
    setPasswordComplexity(state, passwordComplexity) {
      state.passwordComplexity = passwordComplexity;
    },
    ajaxState(state, value) {
      state.ajaxState = value;
    },
    ping(state, value) {
      state.isPing = value;
    },
    setInstallerProgress(state, val) {
      val = val >= 100 ? 100 : val;
      state.installerDownload.progress = val;
    },
    setChat(state, chatConfig) {
      state.hubSpotChatConfig = chatConfig;
      state.hubSpotChatConfig.loaded = false;
    },
    loadChat(state, value) {
      state.hubSpotChatConfig.loaded = value;
    },
    setCanShowTrialMessage(state, value) {
      state.trial.canShow = value;
    },
    setTrialExpirationDate(state, value) {
      if (value) {
        let expiration = Date.parse(value);
        let now = Date.now();
        let difference = Math.abs(now - expiration);
        if (now > expiration) {
          state.trial.daysLeft = 0;
        } else {
          state.trial.daysLeft = Math.trunc(difference / (1000 * 3600 * 24));
        }
      }
    },
  },
  actions: {
    login({ commit }, { user, token, route = true }) {
      commit('login', { user, token });
      if (token) localStorage.setItem('thinfinity.workspaces.token', token);
      if (route) router.push(routerConfig.defaultRoute);
    },
    logout({ commit }, { route = true } = {}) {
      commit('logout');
      localStorage.removeItem('thinfinity.workspaces.token');

      if (route) router.push('/login');
    },
    downloadInstaller({ commit, state }) {
      if (!state.installerDownload.started) {
        state.isDownload = true;
        state.installerDownload.started = true;
        state.installerDownload.completed = false;
        state.installerDownload.error = false;
        state.installerDownload.errorMsg = null;
        state.installerDownload.progress = 0;
        adminService
          .downloadInstaller((val) => {
            commit('setInstallerProgress', val);
          })
          .then(() => {
            state.isDownload = false;
            state.installerDownload.started = false;
            state.installerDownload.completed = true;
          })
          .catch((e) => {
            state.installerDownload.error = true;
            state.installerDownload.errorMsg = e.message || 'Download Error';
            state.installerDownload.started = false;
            state.installerDownload.completed = false;
            state.isDownload = false;
          });
      }
    },
    cancelDownload({ state }) {
      if (state.installerDownload.started) {
        adminService.cancelDownload();
        state.isDownload = false;
        state.installerDownload.started = false;
        state.installerDownload.completed = false;
      }
    },
  },
  getters: {
    role: (state) => state.auth.user.role,
    checkPasswordComplexity: (state) => (password) => {
      let ok =
        password.length >= state.passwordComplexity.minlen &&
        password.length <= state.passwordComplexity.maxlen;
      let re = /[a-z]/;
      if (state.passwordComplexity.mustContainLowercase) {
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainUppercase) {
        re = /[A-Z]/;
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainNumbers) {
        re = /[0-9]/;
        ok = ok && re.test(password);
      }
      if (state.passwordComplexity.mustContainNonAlphanumeric) {
        re = /[\W]/;
        ok = ok && re.test(password);
      }
      return ok;
    },
    menuOptions: (state) => {
      const options = state.menu.options;

      const t = options
        .filter((t) => (t._showIf ? t._showIf(state) : true))
        .map((t) => {
          const { _showIf, ...rest } = t;
          return rest;
        });

      return t;
    },
  },
});
