import { apiConfig } from '../config/backend-api';
import axios from 'axios';
import { store } from '../store';
import { adminService } from './admin.service';
import { v4 as uuidv4 } from 'uuid';

//const lsTokenName = 'thinfinity.workspaces.token';
export const authService = {
  login,
  logout,
  register,
  confirmEmail,
  userRole,
  getTokenFromStorage
};

export const userRole = {
  USER: 1,
  ADMIN: 2,
  OWNER: 3,
};

export const userStatus = {
  PENDING: 1,
  ACTIVE: 2,
  INACTIVE: 3,
  LOCKED: 4,
};

export function getTokenFromStorage() {
  let token = null;
  try {
    token = localStorage.getItem('thinfinity.workspaces.token') ?? null;
  } catch{ /* defaults to null */ }
  return token;
}

function login(accountid, email, password) {
  let credentials = {clientid: uuidv4(), accountid, email, password };
  return axios
    .post(`${apiConfig.baseUrl}/auth/login`, credentials, {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then((response) => {
      let r = { response };
      const token = r.response.data.data.token;
      let user = adminService.parseUserData(r.response.data.data);
      store.dispatch('login', { user, token });
      store.commit('setTrialExpirationDate', r.response.data.data.trialExpirationDate);
    });
}

function logout() {
  store.dispatch('logout');
}

function register(user) {
  return axios.post(`${apiConfig.baseUrl}/auth/register`, user, {
    headers: { 'Content-Type': 'application/json' },
  });
}

function confirmEmail(usertoken) {
  return axios.post(
    `${apiConfig.baseUrl}/auth/verifyuser`,
    { usertoken: usertoken },
    { headers: { 'Content-Type': 'application/json' } }
  );
}
