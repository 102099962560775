export const ServiceModel = {
  id: '',
  name: '',
  type: '',
  domain: '',
  gatewayURL: '',
  networkId: '',
  subdomain: '',
  machine: '',
  license: {
    serial: '',
    expires: '',
    limits: {
      users: 0,
    },
  },
  features: {
    authentications: {
      workspace: {
        enabled: false,
      },
      activeDirectory: {
        enabled: false,
        available: false,
      },
      google: false,
      facebook: false,
      linkedin: false,
      microsoft: false,
      secondFactor: {
        enabled: false,
      },
    },
    advancedAuthentication: false,
    privateProfiles: false,
    privateGateway: {
      enabled: false,
      networkId: '',
      url: '',
    },
    scrapers: {
      rdp: {enabled:false,available:false},
      vnc: {enabled:false,available:false},
      zsc: {enabled:false,available:false},
      dav: {enabled:false,available:false},
      lnk: {enabled:false,available:false},
      rfb: {enabled:false,available:false},
      fmx: {enabled:false,available:false},
      rpxy: {enabled:false,available:false}
    },
  },
};
